Bu sayfadaki puf noktalar:
1 - bu sayfa SepetOdemePage > 4cu asamada odeme tip 3 ise sepet ozeti alanindaki buton yerine gosterilecek
<template>
    <div>
        <!-- @submit.prevent="fetchGaranti3dModel()" -->
        <form ref="form" action="https://sanalposprovtest.garanti.com.tr/servlet/gt3dengine" method="post" target="_blank">
            <div style="display:none">
                3D Security Level:
                <select name="secure3dsecuritylevel">
                <option value="3D">3D</option>
                </select>
                <br />
                Card Number: <input name="cardnumber" type="text"  :value="kartNo" />
                <br />
                Expire Date (mm): <input name="cardexpiredatemonth" type="text"   :value="validMonth"/>
                <br />
                Expire Date (yy): <input name="cardexpiredateyear" type="text"  :value="validYear" />
                <br />
                CVV2: <input name="cardcvv2" type="text"  :value="cvv" />
                <br />
            </div>
            <input type="hidden" name="mode" :value="strMode" />
            <input type="hidden" name="apiversion" :value="strApiVersion" />
            <input type="hidden" name="terminalprovuserid" :value="strTerminalProvUserID" />
            <input type="hidden" name="terminaluserid" :value="strTerminalUserID" />
            <input type="hidden" name="terminalmerchantid" :value="strTerminalMerchantID" />
            <input type="hidden" name="txntype" :value="strType" />
            <input type="hidden" name="txnamount" :value="strAmount" />
            <input type="hidden" name="txncurrencycode" :value="strCurrencyCode" />
            <input type="hidden" name="txninstallmentcount" :value="strInstallmentCount" />
            <input type="hidden" name="orderid" :value="strOrderID" />
            <input type="hidden" name="terminalid" :value="strTerminalID" />
            <input type="hidden" name="successurl" :value="strSuccessURL" />
            <input type="hidden" name="errorurl" :value="strErrorURL" />
            <input type="hidden" name="customeremailaddress" :value="strcustomeremailaddress" />
            <input type="hidden" name="customeripaddress" :value="strCustomeripaddress" />
            <input type="hidden" name="secure3dhash" :value="hast_data" />
            <!-- <button type="submit">kredi karti bilgilerini gonder</button> -->
        </form>
    </div>
</template>

<script>

export default {
    props : ["kartNo","validMonth","validYear","cvv","garantiFormGonder"],
    data(){
        return{
            strMode : "TEST",
            strApiVersion : "v0.01",
            strTerminalProvUserID : "PROVAUT",
            strType : "sales",
            strAmount : "100", // slem Tutarı
            strCurrencyCode : "949",
            strInstallmentCount : "", //Taksit Sayısı. Bos gönderilirse taksit yapılmaz
            strTerminalUserID : "PROVAUT",
            strOrderID : "",
            strCustomeripaddress : "127.0.0.1",
            strcustomeremailaddress : "info@tradesis.com",
            strTerminalID : "30691297",
            strTerminalID_ : "030691297", //Basına 0 eklenerek 9 digite tamamlanmalıdır.
            strTerminalMerchantID : "7000679", //Üye syeri Numarası
            strStoreKey : "12345678", //3D Secure sifreniz
            strProvisionPassword : "123qweASD/", //Terminal UserID sifresi
            strSuccessURL : "https://gunkaysanalmarket.zdc.com.tr/garanti_3dresult.php", // gonderme asamasinda bu alan duzenlenir
            strErrorURL : "https://gunkaysanalmarket.zdc.com.tr/garanti_3dresult.php", // gonderme asamasinda bu alan duzenlenir
            security_data : "",
            hast_data : "",
        }
    },
    methods : {
        submit(){ // formu disaridan tanimli bir butonla tetikleyip calistirilir
            this.$refs.form.submit()
        },
        kartBilgileriniGonder(){
            this.strOrderID = 'ayxan'+Math.floor(Math.random() * 1000); // random (silinecek)
            // console.log(this.strOrderID) // random (silinecek)
            // success ve error url-lerinin duzenlenmesi
            // var baseUrl = window.location.origin
            // this.strSuccessURL = baseUrl + '/sepet/odeme/kart-basarili' // success url dinamik olarak guncellenir
            // this.strErrorURL = baseUrl + '/sepet/odeme/kart-basarili' // error url dinamik olarak guncellenir
            // https://sanalposprovtest.garanti.com.tr/servlet/gt3dengine?secure3dsecuritylevel=3D&cardnumber=375622005485014&cardexpiredatemonth=&cardexpiredateyear=&cardcvv2=123&mode=TEST&apiversion=v0.01&terminalprovuserid=PROVAUT&terminaluserid=PROVAUT&terminalmerchantid=7000679&txntype=sales&txnamount=100&txncurrencycode=949&txninstallmentcount=&orderid=ayxan361&terminalid=30691297&successurl=https%3A%2F%2Fgunkaysanalmarket.zdc.com.tr%2Fgaranti_3dresult.php&errorurl=https%3A%2F%2Fgunkaysanalmarket.zdc.com.tr%2Fgaranti_3dresult.php&customeremailaddress=info%40tradesis.com&customeripaddress=127.0.0.1&secure3dhash=625F7BE87EA37A848D8DA1A32E1463B584CB0E2F
            // islemler basliyor
            var sha1 = require('js-sha1')
            var a = this.strProvisionPassword + this.strTerminalID_
            this.security_data = sha1(a).toUpperCase()
            var b = this.strTerminalID + this.strOrderID + this.strAmount + this.strSuccessURL + 
            this.strErrorURL + this.strType + this.strInstallmentCount + this.strStoreKey + this.security_data
            this.hast_data = sha1(b).toUpperCase()
                // secure3dsecuritylevel: '3D',
                // cardnumber: this.kartNo,
                // cardexpiredatemonth: this.validMonth,
                // cardexpiredateyear: this.validYear,
                // cardcvv2: this.cvv,
                // mode: this.strMode,
                // apiversion: this.strApiVersion,
                // terminalprovuserid: this.strTerminalProvUserID,
                // terminaluserid: this.strTerminalUserID,
                // terminalmerchantid: this.strTerminalMerchantID,
                // txntype: this.strType,
                // txnamount: this.strAmount,
                // txncurrencycode: this.strCurrencyCode,
                // txninstallmentcount: this.strInstallmentCount,
                // orderid: this.strOrderID,
                // terminalid: this.strTerminalID,
                // successurl: this.strSuccessURL,
                // errorurl: this.strErrorURL,
                // customeremailaddress: this.strcustomeremailaddress,
                // customeripaddress: this.strCustomeripaddress,
                // secure3dhash: this.hast_data,
        },
    },
    created(){
        // create asamasinda degerlerin ici doldurulur. post icin sorun cikarmasin diye boyle yapildi.
        // eskiden submit() fonksiyonu icinde yapiliyordu ve sistem hatasi veriyordu.
        // form ilk defa tetiklendiginde kartBilgileriniGonder() icindeki degerler dogru sekilde ve zamaninda doldurulmuyor sanirim
        // ama ikincide hic bir sorun cikarmiyordu. o yuzden kartBilgileriniGonder() created aninda cagriliyor
        // ve degerler created aninda doldurulup hazirda bekletiliyor
        this.kartBilgileriniGonder()
    },
    watch: {
        garantiFormGonder(val) { // sepet odeme sayfasinda odeme tip 3 ise bu deger true yapilir
            // data degistiginde bir seyler yapilir
            if(val == true) {
                this.submit() // form tetiklenir ve sorgu yapilir
                // console.log("degisti")
            }
        },
    },
}
</script>

<style scoped>

</style>